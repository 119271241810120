import { EnvDesigner } from '@bend/env';

export const environment: EnvDesigner = {
  name: 'test',
  release: 'app-designer-front-test@SMSPR-2095-fsd-part-3',
  production: false,

  wssHost: 'wss://api-test.smilein.io',
  storageHost: 'https://smilein.fra1.cdn.digitaloceanspaces.com',
  apiHost: 'https://api-test.smilein.io',

  appHost: 'https://app-test.smilein.io',
  qrHost: 'https://qr-test.smilein.io',
  nfcHost: 'https://nfc-test.smilein.io',

  userType: 'admin',

  googleApiKey: 'AIzaSyDnhgHqeEQAxqV48CtfjyA23dI22qZkCNo',
};
