import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, pipe, UnaryFunction } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  mergeAll,
  mergeMap,
  switchMap,
} from 'rxjs/operators';

import { OrderService } from '@bend/store/src/lib/order';
import { OrderItemsService } from '@bend/store/src/lib/order-items';
import { OrderLocationsService } from '@bend/store/src/lib/order-locations';
import { OrderUsersService } from '@bend/store/src/lib/order-users';
import {
  OrderItem,
  OrderItemCreationMode,
  OrderItemStatus,
  OrderLocation,
  OrderUser,
  OrderUserTransaction,
} from '@bend/store-shared';

import { StatusPriority } from '../../../../config';
import { LocationPriorityStatus, PriorityStatus } from '../../../../types';
import { OrderUserCurrentService } from '../order-user-current/order-user-current.service';

@Injectable()
export class OrderStatusServices {
  constructor(
    private _translate: TranslateService,
    private _parser: TranslateParser,
    private _sanitizer: DomSanitizer,
    private _orderLocations: OrderLocationsService,
    private _orderItems: OrderItemsService,
    private _orderUserCurrent: OrderUserCurrentService,
    private _ordersUser: OrderUsersService,
    private _order: OrderService,
  ) {}

  /**
   * @description get status by priority
   */
  get orderStatusChanged(): Observable<PriorityStatus> {
    return this._currentUsers.pipe(
      switchMap(users =>
        combineLatest(
          users.map(sessionVisitor =>
            this._orderLocations.bySessionVisitorId(sessionVisitor.id).pipe(
              switchMap(locations => {
                /**
                 * return empty locations to set empty status
                 */
                if (!locations.length) return of([]);

                return combineLatest(locations.map(({ id }) => this.locationStatusChanged(sessionVisitor.id, id)));
              }),
              this._getStatusHightPriority(),
            ),
          ),
        ),
      ),
      this._getStatusHightPriority(),
    );
  }

  /**
   * @description get status by priority
   */
  get locationsStatusChanged(): Observable<LocationPriorityStatus[]> {
    return this._currentUsers.pipe(
      switchMap(users =>
        combineLatest(
          users.map(sessionVisitor =>
            this._orderLocations
              .bySessionVisitorId(sessionVisitor.id)
              .pipe(
                switchMap(locations =>
                  combineLatest(
                    locations.flatMap(({ id, name }) =>
                      this.locationStatusChanged(sessionVisitor.id, id).pipe(map(status => ({ id, name, ...status }))),
                    ),
                  ),
                ),
              ),
          ),
        ),
      ),
      map(locations => locations.flat(1)),
      // remove duplications
      map(locations =>
        locations.filter(
          ({ id }, index) =>
            // find first location in array and check is the same index as this location
            locations.findIndex(({ id: dupeId }) => id === dupeId) === index,
        ),
      ),
    );
  }

  get transactionStatusChanged(): Observable<OrderUserTransaction> {
    return this._order.currentOrderUserId.pipe(
      switchMap(orderUserId => this._ordersUser.orderUser(orderUserId).pipe(first())),
      map(({ transactions = [] }) =>
        transactions.length
          ? transactions[transactions.length - 1]
          : {
              id: null,
              amount: null,
              status: null,
              orderUserId: null,
              createdAt: null,
              updatedAt: null,
              provider: null,
            },
      ),
      distinctUntilKeyChanged('status'),
    );
  }

  transactionStatusChangedById(transactionId: number): Observable<OrderUserTransaction> {
    return this._ordersUser.all.pipe(
      mergeMap(orderUsers => orderUsers.map(({ transactions }) => transactions)),
      mergeAll(),
      filter(({ id }) => id === transactionId),
    );
  }

  /**
   * @description get status by priority
   */
  get itemsStatusChanged(): Observable<(OrderLocation & { items: OrderItem[] })[]> {
    return this._currentUsers.pipe(
      switchMap(users =>
        combineLatest(
          users.map(sessionVisitor =>
            this._orderLocations.bySessionVisitorId(sessionVisitor.id).pipe(
              switchMap(locations =>
                combineLatest(
                  locations.flatMap(location =>
                    this._orderItems.byLocation(sessionVisitor.id, location.id).pipe(
                      first(),
                      map(items => ({ ...location, items })),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
      map(locations => locations.flat(2)),
    );
  }

  /**
   * @description takes the highest scheduled value from the order
   */
  orderScheduledTo(orderId: number, sessionUserId: number): Observable<string> {
    return this._ordersUser.orderUserWithOthers(orderId, sessionUserId).pipe(
      switchMap(users =>
        combineLatest(
          users.map(sessionVisitor =>
            this._orderLocations.bySessionVisitorId(sessionVisitor.id).pipe(
              map(locations => locations.map(({ interpolateData: { scheduledTo } }) => scheduledTo)),
              this._getBigScheduledTo(),
            ),
          ),
        ),
      ),
      this._getBigScheduledTo(),
    );
  }

  get currentOrderScheduledTo(): Observable<string> {
    return combineLatest([this._order.id, this._order.currentOrderUserId]).pipe(
      switchMap(([orderId, sessionUserId]) => this.orderScheduledTo(orderId, sessionUserId)),
    );
  }

  /**
   * @description check if all statutes are the same for partial order
   */
  get allStatusAreTheSameChanged(): Observable<boolean> {
    return this._currentUsers.pipe(
      switchMap(users =>
        combineLatest(
          // get all location for user
          users.map(sessionVisitor =>
            this._orderLocations.bySessionVisitorId(sessionVisitor.id).pipe(
              // get all items for all user locations
              switchMap(locations =>
                combineLatest(locations.map(({ id }) => this._orderItems.byLocation(sessionVisitor.id, id))),
              ),
              // merge all items from all locations
              map(items => items.flat()),
            ),
          ),
        ),
      ),
      // merge all locations from all users
      map(users => users.flat()),
      map(items => items.map(({ status }) => status)),
      // check all the statuses are the same
      map(items => new Set(items).size === 1),
    );
  }

  locationStatusChanged(userId: number, locationId: number): Observable<PriorityStatus> {
    return this._orderItems.byLocation(userId, locationId).pipe(
      map(items => items.map(({ status, updatedAt, creationMode }) => ({ status, updatedAt, creationMode }))),
      this._getStatusHightPriority(),
      distinctUntilChanged(),
    );
  }

  createLabel(label: string, interpolate: Dictionary<string | number> = {}): SafeHtml {
    // uses interpolation separately so that message that is not in i18n can be interpolated
    // instant (instant(key, interpolateParams)) has interpolation but if it does not find
    // key in i18n it does not interpolate the message
    const translateLabel = this._translate.instant(label);
    const interpolateLabel = this._parser.interpolate(translateLabel, interpolate);

    return this._sanitizer.bypassSecurityTrustHtml(interpolateLabel);
  }

  private get _currentUsers(): Observable<OrderUser[]> {
    return combineLatest([
      // get current user
      this._orderUserCurrent.current,
      // get users created by current user
      this._orderUserCurrent.others,
    ]).pipe(
      // merge users in the same array
      map(users => users.flat()),
    );
  }

  // add partially
  private _getStatusHightPriority(): UnaryFunction<Observable<PriorityStatus[]>, Observable<PriorityStatus>> {
    return pipe(
      map(statuses =>
        statuses.reduce(
          (acc: PriorityStatus & { value: number }, { status, updatedAt, creationMode }) => {
            const priority = StatusPriority[status] >= acc.value;

            const priorityStatus = priority ? status : acc.status;

            /**
             * if status is more priority
             * we get biggest updatedAt
             */
            const priorityUpdatedAt =
              priority && updatedAt.getTime() > acc.updatedAt.getTime() ? updatedAt : acc.updatedAt;

            /**
             * if status is more priority and is recently updated
             * we return creationMode from last updated item
             */
            const priorityCreationMode =
              priority && updatedAt.getTime() > acc.updatedAt.getTime() ? creationMode : acc.creationMode;

            const priorityValue = priority ? StatusPriority[status] : acc.value;

            return {
              status: priorityStatus,
              updatedAt: priorityUpdatedAt,
              creationMode: priorityCreationMode,
              value: priorityValue,
            };
          },
          {
            status: OrderItemStatus.Empty,
            updatedAt: new Date(null),
            value: StatusPriority[OrderItemStatus.Closed],
            creationMode: OrderItemCreationMode.Server,
          },
        ),
      ),
      map(({ status, updatedAt, creationMode }) => ({ status, updatedAt, creationMode })),
    );
  }

  private _getBigScheduledTo(): UnaryFunction<Observable<string[]>, Observable<string>> {
    return pipe(
      map(items =>
        items.reduce(
          (acc: string, scheduledTo) => (new Date(acc).getTime() < new Date(scheduledTo).getTime() ? scheduledTo : acc),
          null,
        ),
      ),
    );
  }
}
