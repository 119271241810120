import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItemStatus, TransactionProvider, TransactionStatus } from '@bend/store-shared';

import { OrderDialogLabels } from '../../../../config';
import { DialogKey } from '../../../../types';
import { OrderDialogDisplayedService } from '../order-dialog-displayed/order-dialog-displayed.service';

@Injectable()
export class OrderDialogTransactionsStatusService {
  constructor(private _dialog: DialogService, private _orderDialogDisplayed: OrderDialogDisplayedService) {}

  successBehavior(): MonoTypeOperatorFunction<any> {
    const allowOrderStatus = new Set([
      OrderItemStatus.Empty,
      OrderItemStatus.New,
      OrderItemStatus.Ordered,
      OrderItemStatus.PaymentInProgress,
    ]);

    const successStatuses = new Set([TransactionStatus.Sent]);

    const notAllowedProviders = new Set([TransactionProvider.Pos, TransactionProvider.Waiter]);

    return tap(([{ status, updatedAt, provider, id }, { status: orderStatus }]) => {
      if (!successStatuses.has(status) || !allowOrderStatus.has(orderStatus) || notAllowedProviders.has(provider))
        return;

      if (this._orderDialogDisplayed.check(new Date(updatedAt), id, DialogKey.Payment)) return;

      this._dialog.success({
        message: OrderDialogLabels.PaymentSentToPos,
      });
    });
  }

  errorBehavior(): MonoTypeOperatorFunction<any> {
    const allowOrderStatus = new Set([
      OrderItemStatus.Empty,
      OrderItemStatus.New,
      OrderItemStatus.Ordered,
      OrderItemStatus.PaymentInProgress,
    ]);

    const successStatuses = new Set([TransactionStatus.NotSent]);

    return tap(([{ status, updatedAt, id }, { status: orderStatus }]) => {
      if (!successStatuses.has(status) || !allowOrderStatus.has(orderStatus)) return;

      if (this._orderDialogDisplayed.check(new Date(updatedAt), id, DialogKey.Payment)) return;

      this._dialog.error({
        message: OrderDialogLabels.PaymentError,
      });
    });
  }
}
