import { Injectable } from '@angular/core';
import { combineLatest, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { DialogService } from '@bend/dialog';
import { OrderItemStatus, OrderUserTransaction, TransactionStatus } from '@bend/store-shared';
import { TranslateWrapperService } from '@bend/translate';

import { OrderDialogLabels, OrderDialogLocationStatus } from '../../../../config';
import { AppType, PriorityStatus } from '../../../../types';
import { OrderStatusServices } from '../order-status/order-status.service';

@Injectable()
export class OrderDialogProgressService {
  private _delayDialog: NodeJS.Timeout | undefined;

  constructor(
    private _orderStatus: OrderStatusServices,
    private _dialog: DialogService,
    private _translateWrapper: TranslateWrapperService,
  ) {}

  paymentBehavior(): MonoTypeOperatorFunction<[OrderUserTransaction, PriorityStatus]> {
    const allowTransactionStatus = new Set([TransactionStatus.Accepted]);

    const allowOrderStatus = new Set([OrderItemStatus.PaymentInProgress, OrderItemStatus.Ordered]);

    return tap(([{ status }, { status: orderStatus }]) => {
      // show only in status allowed
      if (!allowTransactionStatus.has(status) || !allowOrderStatus.has(orderStatus)) {
        return;
      }

      this._showDialog();
    });
  }

  orderedBehavior(): MonoTypeOperatorFunction<[OrderItemStatus, unknown, unknown, unknown, AppType]> {
    const allowOrderStatus = new Set([OrderItemStatus.OrderedInProgress]);

    return tap(([status]) => {
      // show only in status allowed
      if (!allowOrderStatus.has(status)) {
        // if status changed remove delay dialog
        this.clearDelayDialog();

        return;
      }

      this._showDialog();
      // Disable call of DelayDialog
      // this._showDelayDialog(ORDER_DIALOG_IN_PROGRESS_DELAY[appType]);
    });
  }

  clearDelayDialog(): void {
    clearTimeout(this._delayDialog);
  }

  private _showDialog(): void {
    // show in progress modal
    this._dialog.progress({
      message: this._orderStatus.locationsStatusChanged.pipe(
        first(),
        map(locations => (locations.length < 2 ? OrderDialogLabels.InProgress : OrderDialogLabels.InProgressPart)),
      ),
      interpolateData: this._locationStatuses,
    });
  }

  private _showDelayDialog(message: string): void {
    // display message after 5e4 is 50 * 1000 50s
    const timeToShowDelayDialog = 5e4;

    this._delayDialog = setTimeout(() => this._dialog.info({ message }), timeToShowDelayDialog);
  }

  private get _locationStatuses(): Observable<{ locations: string }> {
    return this._orderStatus.locationsStatusChanged.pipe(
      switchMap(locations =>
        // check user locations
        locations.length < 2
          ? // if the user has only one location, the location status is not displayed
            of({ locations: '' })
          : // concatenate all locations into a string with a new row as a divider.
            of(locations).pipe(
              switchMap(items =>
                combineLatest(
                  items.map(({ name: locationName, status }) =>
                    this._translateWrapper.stream(OrderDialogLocationStatus[status], { locationName }),
                  ),
                ),
              ),
              map(items => ({ locations: items.join('\n') })),
            ),
      ),
    );
  }
}
