import { Icons } from '@bend/icon';

import { TpaModel } from './payment-config.type';
import { PaymentMethods } from './user-transactions.type';

export interface PaymentRequest<T> {
  orderItemsIds?: T[];
  amount?: number;
  loyaltyCode?: string;
  redirectUrl: string;
  useCredit: boolean;
  paymentMethod: PaymentMethod;
  acceptTermsAndConditions?: boolean;
}

export type Payment<T = PaymentType> = PaymentSuccess | PaymentProvider<T>;

/**
 * @description when the card is saved and instant payment is made
 */
export interface PaymentSuccess {
  paid: boolean;
}

export interface PaymentProvider<T = PaymentType> {
  type: T;
  acceptMethods: PaymentAcceptMethods[];
  data: PaymentData<T>;
}

export type PaymentData<T = PaymentType> = T extends PaymentType.Stripe
  ? PaymentStripe
  : T extends PaymentType.Nepting
    ? PaymentNepting
    : T extends PaymentType.Paygreen
      ? PaymentPaygreen
      : T extends PaymentType.Adyen
        ? PaymentAdyen
        : T extends PaymentType.Lyf
          ? PaymentLyf
          : T extends PaymentType.Tpa
            ? PaymentTpa
            : T extends PaymentType.Yavin
              ? PaymentYavin
              : PaymentEpay;

export const enum PaymentAcceptMethods {
  CreditCard = 'credit_card',
}

export interface PaymentYavin {
  amount: number;
  mode: string;
  paymentLink: string;
  transactionId: number;
}

export interface PaymentStripe {
  clientSecret: string;
  stripeAccount: string;
  publishableKey: string;
  amount: number;
  enableGPay: boolean;
  enableApplePay: boolean;
}

export interface PaymentNepting {
  url: string;
  meta: NeptingMeta;
}

type ProviderMode = 'dev' | 'prod';

export interface PaymentTpa {
  mode: ProviderMode;
  model: TpaModel;
  transactionId: number;
}

export interface PaymentEpay {
  amount: string;
  meta: {
    accepturl: string;
    amount: string;
    callbackurl: string;
    currency: string;
    merchantnumber: string;
    orderid: string;
  };
}

type Currency = 'eur';
type Plateform = 'bank_card' | 'conecs';
type PaymentOrderStatus =
  | 'payment_order.authorized'
  | 'payment_order.error'
  | 'payment_order.expired'
  | 'payment_order.pending'
  | 'payment_order.refused'
  | 'payment_order.successed';

export interface PaymentPaygreen {
  publicKey: string;
  amount: number;
  enableApplePay: boolean;
  paymentOrder: {
    id: string;
    object: string;
    object_secret: string;
    amount: number;
    auto_capture: boolean;
    capture_on: string | null;
    buyer: {
      id: string;
      reference: string;
      billing_address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
      };
      first_name: string;
      last_name: string;
      email: string;
    };
    cancel_url: string;
    code: string | null;
    currency: Currency;
    cycle: string | null;
    description: string;
    integration_mode: string | null;
    mode: string;
    notification_url: string;
    occurrences: string | null;
    original_amount: number;
    partial_allowed: boolean;
    max_operations: string | null;
    platforms: Plateform[];
    reference: string;
    return_url: string;
    shipping_address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    shop_id: string;
    status: PaymentOrderStatus;
    transactions: [];
    platform_options: {
      bank_card: {
        type: string;
        reuse_card_proposal: boolean;
      };
      conecs: {
        reuse_card_proposal: boolean;
      };
    };
  };
  mode: ProviderMode;
}

export const PAYGREEN_PAYMENT_METHODS: Partial<Record<string, string>> = {
  [PaymentMethods.CreditCard]: 'bank_card',
  [PaymentMethods.RestaurantTicket]: 'conecs',
  [PaymentMethods.Swile]: 'swile',
  [PaymentMethods.ApplePay]: 'apple_pay',
};

export interface PaymentAdyen {
  amount: number;
  clientKey: string;
  mode: ProviderMode;
  enableGPay: boolean;
  enableApplePay: boolean;
  session: {
    amount: { currency: string; value: number };
    expiresAt: Date;
    id: string;
    merchantAccount: string;
    reference: string;
    returnUrl: string;
    sessionData: string;
  };
}

export interface PaymentLyf {
  transactionId: number;
  amount: number;
  mode: ProviderMode;
  url: string;
}

export const LYF_STORAGE_KEY = 'lyf-payment-response';

export interface NeptingMeta {
  nep_APIVersion: string;
  nep_Amount: number;
  nep_Currency: number;
  nep_Enrolment: number;
  nep_IFrameEnabled: number;
  nep_Language: string;
  nep_MerchantID: string;
  nep_MerchantLabel: string;
  nep_Mode: number;
  nep_Sign: string;
  nep_TransactionID: string;
  nep_UrlCancel: string;
  nep_UrlError: string;
  nep_UrlRefused: string;
  nep_UrlStatus: string;
  nep_UrlSuccess: string;
}

export interface PaymentMethod {
  id: string;
  provider: PaymentType;
  details: {
    brand: Icons;
    last4: number;
  };
}

export const enum PaymentType {
  Nepting = 'nepting',
  Stripe = 'stripe',
  Epay = 'epay',
  Paygreen = 'paygreen',
  Adyen = 'adyen',
  Lyf = 'lyf',
  Tpa = 'tpa',
  Yavin = 'yavin',
}

/*
 * studio payment settings
 */

export enum PaymentMethodType {
  ApplePay = 'apple_pay',
  CreditCard = 'credit_card',
  GooglePay = 'google_pay',
  RestaurantTickets = 'restaurant_tickets',
  Swile = 'swile',
  UserCredit = 'user_credit',
  Lyf = 'lyf',
}

export interface SettingsPayment {
  [PaymentMethodType.ApplePay]: PaymentMethodSettings;
  [PaymentMethodType.CreditCard]: PaymentMethodSettings;
  [PaymentMethodType.GooglePay]: PaymentMethodSettings;
  [PaymentMethodType.RestaurantTickets]: PaymentMethodSettings;
  [PaymentMethodType.Swile]: PaymentMethodSettings;
  [PaymentMethodType.Lyf]: PaymentMethodSettings;
}

export interface PaymentMethodSettings {
  providerId: number;
  enabled: boolean;
}
